import React from "react";

import BusinessLendingSEO from "../../components/services/business-lending/businessLendingSEO";
import BlogsSection from "../../components/shared/blogsSection";
import TestimonialsCarousel from "../../components/shared/testimonials";
import { Query } from "@graphql-types";
import { PageProps, graphql } from "gatsby";
import TabsLink from "@components/shared/sub/tabsLink";
import { Ctas, Header, IframeEmbed, TextButtons } from "@global";

interface Props extends PageProps {
  data: Query;
}

const BusinessLending = ({ data }: Props) => {
  if (data.sanityBusinessLendingPage == null) return null;

  const {
    textAndButtons,
    ctas,
    header,
    title,
    iframeEmbed,
    topTextButtons,
  } = data.sanityBusinessLendingPage;
  const shared = data.sanitySharedSection;

  return (
    <div className="subpage-container">
      <BusinessLendingSEO />
      <Header title={title} headerData={header} />

      {shared && shared.servicesTabs && (
        <TabsLink
          tabs={shared.servicesTabs}
          pageColor={header?.headerColor}
          slug="/services/business-loans/"
        />
      )}
      {topTextButtons && <TextButtons data={topTextButtons} />}
      {ctas && <Ctas data={ctas} />}
      {iframeEmbed && <IframeEmbed data={iframeEmbed} />}
      {textAndButtons && <TextButtons data={textAndButtons} />}
      <TestimonialsCarousel color="red" />
      <BlogsSection title="Related Blogs" category="Business Loans" />
    </div>
  );
};

export default BusinessLending;

export const query = graphql`
  query {
    sanityBusinessLendingPage {
      title
      header {
        ...sanityHeader
      }
      topTextButtons {
        ...sanityTextButton
      }
      iframeEmbed {
        ...sanityIframeEmbed
      }
      textAndButtons {
        ...sanityTextButton
      }
      ctas {
        ...sanityCtas
      }
    }
    sanitySharedSection {
      servicesTabs {
        serviceTab {
          _key
          linkText
          url
        }
      }
    }
  }
`;
